<template>
  <main>

    <b-modal id="modalcheckTr" v-model="formModal" ok-only centered size="lg">
      <p class="lead text-success">{{$t('message.ttvs')}}</p>
    </b-modal>

    <div class="container-fluid">
      <b-jumbotron id="jumbotron" border-variant="dark" header-level="5">
        <template #header>{{$t('message.sft_contact_wewa')}}</template>
        <template #lead>
          {{$t('message.sft_contact_wewatxt')}}
        </template>
      </b-jumbotron>
    </div>

    <div id="ContactContainer" class="container-fluid">
      <!-- Content Row -->
      <div class="row">
        <!-- Map Column -->
        <div class="col-sm-12">
          <div class="row">
            <div class="col-xl-8 col-lg-7 col-md-6 col-sm-12 mb-4">
              <div class="iframe_loader">
                <b-embed
                  type="iframe"
                  aspect="16by9"
                  src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3144.8565378454814!2d23.726860076491985!3d37.98047720028305!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzfCsDU4JzQ5LjciTiAyM8KwNDMnNDYuMCJF!5e0!3m2!1sel!2sgr!4v1689686928012!5m2!1sel!2sgr"
                  allowfullscreen
                  loading="lazy"
                ></b-embed>
              </div>
            </div>
            <!-- Contact Details Column -->
            <div class="col-xl-4 col-lg-5 col-md-6 col-sm-12">
              <h1 class="mainTitleContact">{{$t('message.sft_contact_details')}}</h1>
              <div class="mt-2">
                <h5 title="Address" class="savetravellocdetails">{{$t('message.sft_contact_address')}}</h5>
                <span class="detsafspan">{{$t('message.sft_contact_evripidou')}} 12, 10559</span>
              </div>
              <div class="mt-2">
                <h5 title="Phone" class="savetravellocdetails">{{$t('message.sft_contact_phone')}}</h5>
                <span class="detsafspan">(+30) 2107007135</span> 
              </div>
              <div class="mt-2">
                <h5 title="Email" class="savetravellocdetails">{{$t('message.sft_contact_mail')}}</h5>
                <a href="mailto:info@safetravel.today">info@safetravel.today</a>
              </div>
              <div class="mt-2">
                <h5 title="Hours" class="savetravellocdetails">{{$t('message.sft_contact_openhours')}}</h5>
                <span class="detsafspan">{{$t('message.sft_contact_openhoursdets')}}</span> 
              </div>
              <blockquote class="mt-2">
                <p class="mb-0">{{$t('message.sft_contact_smthurgent')}}</p>
                <footer class="blockquote-footer">{{$t('message.sft_contact_team')}}</footer>
              </blockquote>
            </div>
          </div>
        </div>
        <div class="col-sm-12 pt-1">
          <div class="row justify-content-center align-items-center">
            <div class="col-lg-3 col-md-5 col-sm-5">
              <img class="img-fluid" alt="support logo" :src="support_logo">
            </div>
            <div class="col-lg-9 col-md-7 col-sm-7">
              <h1 class="mainTitleContact">{{$t('message.sft_contact_emergassist')}}</h1>
              <h3 class="mt-2 savetravellocdetails">HealthWatch</h3>
              <p class="detsafspan">{{$t('message.sft_contact_phonetf')}} <a href="tel: +302313084533">+ (30) 2313084533</a><br> {{$t('message.ce')}} <a href="mailto:asuaclaims@healthwatch.gr">asuaclaims@healthwatch.gr</a> </p>
              <p class="detsafspan">{{$t('message.sft_contact_ccoperates')}}
              </p>
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-12 text-center">
              <h3 class="savetravellocdetails">{{$t('message.sft_contact_ifcontus')}}</h3>
              <h6 class="detsafspan">{{$t('message.sft_contact_ifcontusoon')}}</h6>
            </div>
          </div>
        </div>
        <div class="col-sm-12 mt-1">
          <!-- Contact Form -->
          <div class="row justify-content-center">
            <div class="col-xl-6 col-lg-8 col-md-10 col-sm-12 mb-4">
              <form @submit.prevent="submitForm">
                <div class="control-group form-group">
                  <div class="controls">
                    <label>{{$t('message.sft_contact_fullname')}}</label>
                    <input type="text" class="form-control" name="name" v-model="formData.name" minlength="8" required :placeholder='$t(`message.sft_contact_placehoname`)'>
                    <p class="help-block"></p>
                  </div>
                </div>
                <div class="control-group form-group">
                  <div class="controls">
                    <label>{{$t('message.sft_contact_phone')}}</label>
                    <input type="tel" class="form-control" name="phone" v-model="formData.phone" pattern=".{6,}" required :placeholder='$t(`message.sft_contact_placehophone`)' onkeyup="this.value=this.value.replace(/([^+0-9]+)/gi, '')">
                  </div>
                </div>
                <div class="control-group form-group">
                  <div class="controls">
                    <label>{{$t('message.sft_contact_mail')}}</label>
                    <input type="email" class="form-control" name="email" v-model="formData.email" minlength="10" required :placeholder='$t(`message.sft_contact_placehomail`)'>
                  </div>
                </div>
                <div class="control-group form-group">
                  <div class="controls">
                    <label>{{$t('message.sft_contact_message')}}</label>
                    <textarea rows="10" cols="100" class="form-control" name="message" v-model="formData.message" minlength="20" required :placeholder='$t(`message.sft_contact_placehomess`)' maxlength="999" style="resize:none"></textarea>
                  </div>
                </div>
                <input type="text" name="_honey" style="display:none">
                <input type="hidden" name="_captcha" value="true">
                <button type="submit" id="submit" class="btn btn-secondary">{{$t('message.sft_contact_sentmessage')}}</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {
  BRow, BCol, BJumbotron, BEmbed
} from 'bootstrap-vue'
export default {
  components: {
    BRow,
    BCol,
    BJumbotron,
    BEmbed
  },
  data() {
    return {
      formModal:false,
      support_logo: require('@/assets/images/quotation/support2.png'),
      formData: {
        name: '',
        phone:'',
        email:'',
        message:'',
      }
    }
  },
  methods: {
    async submitForm() {
      await fetch("https://formsubmit.co/ajax/sales@safetravel.today", {
        method: "POST",
        headers: { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(this.formData)
      })
      .then(response => response.json())
      .then(data => {
        if(data.success) {
          Object.keys(this.formData).forEach(key => this.formData[key]='');
          this.formModal=true
        }
      })
      .catch(error => console.warn(error));
    }
  }
}
</script>
<style scoped>
.iframe_loader {
  background:url('../../assets/images/logo/animated_logo.svg') center center no-repeat;
}
</style>